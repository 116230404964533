import React from 'react';
import Section from '../components/Section';
import Metadata from '../components/Metadata';

function act() {
  return (
    <>
      <Metadata title='ACT' />
      <Section heading='Akzeptanz- und Commitment-Therapie (ACT)'>
        <p>
          Bei der Akzeptanz- und Commitment-Therapie (ACT) handelt sich um eine
          Form der Verhaltenstherapie, die sich z.B. bei Depression, Ängsten,
          Anpassungsstörungen oder Lebenskrisen als wirksam erweist. Vor allem
          im englischsprachigen Raum findet ACT in der Arbeit mit jugendlichen
          und erwachsenen Menschen im Autismus-Spektrum große Beliebtheit, da
          die ACT auf die Erweiterung psychischer Flexibilität abzielt und dabei
          helfen kann, starre Denkmuster aufzulösen. Hierbei geht es weniger um
          die Reduktion von Symptomen, die als unangenehm empfunden werden,
          sondern vielmehr, um die Akzeptanz und Flexibilität auf derartiges
          Erleben reagieren zu können. So geht es unter anderem um folgende
          Zielsetzungen:
        </p>
        <ul>
          <li>
            unangenehme Gefühle und Gedanken wahrzunehmen, ohne sich darin zu
            verlieren
          </li>
          <li>
            Gründe zu erarbeiten, um aktiv zu werden und Vermeidungsstrategien
            zu durchbrechen
          </li>
          <li>
            ein werteorientiertes und sinnerfülltes Leben zu leben, welches dem
            Menschen ermöglicht, mehr von dem zu bekommen, was er sich wünscht
          </li>
        </ul>
      </Section>
    </>
  );
}

export default act;
